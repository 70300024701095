@font-face {
  font-family: Gilroy;
  src: url(./assests/gilroy.otf);
}
@font-face {
  font-family: gilroy-light;
  src: url(./assests/Gilroy-Light.otf);
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: Gilroy !important;
  background-color: #00b4dbac !important;
  background: linear-gradient(
    to right,
    hsl(200, 100%, 40%),
    hsl(191, 100%, 43%)
  );
}
