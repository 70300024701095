.footer {
  border-top: 2px solid rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 0.9rem;
  padding: 1rem 0;
  margin-top: 0.5rem;
}
.footer__link:focus {
  outline: auto;
  outline-width: auto !important;
  outline-style: solid !important;
  outline-color: rgba(253, 253, 253);
}
